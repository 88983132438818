@import 'common/variables.scss';

html {
  scroll-behavior: smooth;
}

#cookie-consent {
  display: none;
  box-shadow: 0px -5px 10px 0px rgba($pob-dark-color, 0.15);
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $pob-light-color-alternate;
  .cookie-content {
    padding: 20px 30px;
    p,
    a {
      font-size: 14px;
    }
    p a {
      color: inherit;
      text-decoration: underline;
    }
  }
  .cookie-buttons {
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.open {
    display: block;
  }
}
