@import '../common/variables.scss';

#pob-kiosk {

  h1.title {
    font-size: 50px * $scale;
    padding-bottom: 25px * $scale;
  }
  .content {
    padding: 16px * $scale 0;
  }
  @include xs {
    h1 {
      font-size: 25px !important;
      padding-bottom: 10px !important;
      padding-top: 15px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 23px !important;
    }
  }
}
