@import "common/variables.scss";

header {
  .navbar-brand {
    flex: 1;
    height: 100%;
  }
  .navbar-menu {
    flex: 1;
    .navbar-end {
      flex: 1;
      justify-content: space-between;
    }
  }
  .navbar-brand .navbar-item {
    padding-left: 20px;
  }
  .navbar-burger.burger {
    display: none;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
  }

  #pob-navbar {
    text-align: center;
  }
  .navbar {
    max-width: 1200px; // * $scale;
    height: 128px * $scale;
    margin: 0 auto;
  }
  .navbar-item {
    font-size: 16px;
    font-weight: 500;
  }
  .navbar-item img {
    max-height: unset;
  }
  .navbar-item .button {
    height: 50px * $scale;
    padding: 13px 44px;
    strong {
      font-size: inherit;
      font-weight: inherit;
    }
    font-size: 16px;
    font-weight: 500;
  }
  .pob-logo {
    height: 42px;
    max-height: 42px;
  }
  @include xs {
    .pob-logo {
      height: 32px !important;
      max-height: 32px !important;
    }
  }
}
