@import "../common/variables.scss";

#yeni-nesil-pos {
  background-color: $pob-background-alternate;
  h1.title {
    font-size: 60px * $scale;
    text-align: center;
    padding-bottom: 60px * $scale;
  }
  @include xs {
    h1 {
      font-size: 25px !important;
      padding-bottom: 10px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 23px !important;
    }
  }
}
