@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueBook.otf) format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueRegular.otf) format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueBold.otf) format('opentype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueBold.otf) format('opentype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueHeavy.otf) format('opentype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Neue';
  src: url(../assets/UniNeue/UniNeueHeavy.otf) format('opentype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
