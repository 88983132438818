@import "../common/variables.scss";

#gelecege-ode {
  h1.title {
    font-size: 50px * $scale;
    color: $pob-light-color;
    padding-bottom: 45px * $scale;
  }
  background-color: $pob-dark-color;
  color: $pob-light-color;

  @include xs {
    h1 {
      font-size: 25px !important;
      padding-bottom: 10px !important;
      padding-top: 15px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 23px !important;
    }
  }
}
