@import 'common/variables.scss';

main#subpage {
  #top-hero {
    .hero-body {
      height: auto;
      padding: 80px 0;
      .title {
        text-align: center;
        color: $pob-light-color;
      }
    }
  }
}
