@import 'common/variables.scss';

footer.footer {
  background-color: $pob-dark-color;
  color: $pob-light-color-alternate;
  font-size: 16px;
  a {
    color: $pob-light-color-alternate;
  }
  .topkapi-logo {
    background: white;
    padding: 10px * $scale;
    height: 50px;
  }
  .pob-logo {
    padding-top: 8px;
    height: 50px;
  }
  .footer-navbar a {
    padding-right: 20px;
  }
  .content-container {
    padding-top: 20px * $scale;
    padding-bottom: 30px * $scale;
    margin: 0 auto;
    max-width: $max-width;
  }
  .footer-top-section {
    padding-bottom: 24px * $scale;
  }
  .footer-bottom-section {
    padding-top: 24px * $scale;
  }
  .footer-right {
    text-align: right;
  }

  @media only screen and (max-width: $tablet - 1px) {
    .footer-right,
    .footer-left {
      text-align: center;
    }
    .footer-navbar a {
      display: block;
      padding-bottom: 10px;
    }
  }
}
