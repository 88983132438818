@import "../common/variables.scss";

#akilli-analiz {
  background-color: $pob-background-alternate;
  padding: 40px * $scale;

  .border {
    border: 8px solid $pob-primary-color;
    position: relative;

    .vertical-line {
      display: flex;
      align-items: center;
      position: absolute;
      left: 28%;
      top: -8px;

      .line {
        width: 8px;
        height: 310px;
        background: $pob-primary-color;
      }

      .erase {
        width: 8px;
        height: 310px;
        background: $pob-background-alternate;
      }

      @include md {
        left: 20%;
      }

      @include lt-md {
        display: none;
      }
    }
  }

  h1.title {
    font-size: 90px * $scale;
    text-align: center;
    padding-bottom: 90px * $scale;
  }

  p {
    font-size: 32px * $scale;
    line-height: 1.5;
    b {
      font-weight: 600;
    }
  }

  .section-image {
    background-position: center;
    background-size: contain;
  }

  @include xs {
    h1 {
      font-size: 25px !important;
      padding-bottom: 10px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
}
