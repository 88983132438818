@import "../common/variables.scss";

main {
  section {
  }

  .section-image {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  .content p {
    font-weight: 300;
    font-size: 20px;
    line-height: 40px;
  }

  @media only screen and (max-width: $tablet - 1px) {
    .section-image {
      width: 80%;
      padding-top: 80%;
      background-position: center;
    }
    .column {
      display: flex;
      justify-content: center;
    }
  }

  .content-container {
    padding-top: 120px * $scale;
    padding-bottom: 120px * $scale;
    margin: 0 auto;
    max-width: $max-width;
    @include xs {
      padding-top: 0px;
      padding-bottom: 0px !important;
    }
  }

  @media only screen and (max-width: $tablet - 1px) {
    .content-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@import "hero.scss";
@import "neden-pob.scss";
@import "pob-qrMenu.scss";
@import "yeni-nesil-pos.scss";
@import "gelecege-ode.scss";
@import "pob-kiosk.scss";
@import "sizi-arayalim.scss";
@import "akilli-analiz.scss";
