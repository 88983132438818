@import '../common/variables.scss';

#pob-qrMenu {
background-color: $pob-primary-color;
  h1.title {
    font-size: 50px * $scale;
    padding-bottom: 25px * $scale;
    color:white;
  }
  .content {
    padding: 16px * $scale 0;
    color:white;
  }
  @include xs{
    h1{
      font-size: 25px !important;
      padding-bottom: 10px !important;
      padding-top: 15px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 23px !important;
    }
  }
}
