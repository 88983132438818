@import "../common/variables.scss";

#sizi-arayalim {
  scroll-snap-margin-top: 120px;
  scroll-margin-top: 120px;
  margin-top: 120px;
  .content-container {
    padding-bottom: 0;
  }
  .hero-body {
    height: auto;
    background: $pob-primary-color url("../assets/POB_Footer_Hero.png") 0% 0%
      no-repeat padding-box;
    background-size: cover;
  }
  @media only screen and (max-width: $tablet - 1px) {
    margin-top: 0px !important;
    scroll-snap-margin-top: 0px !important;
    scroll-margin-top: 0px !important;
    .content-container {
      padding-top: 5px;
      margin-top: -40px !important;
    }
    .hero-body {
      height: auto !important;
      padding: 3rem 0.5rem;
      background: $pob-primary-color;
    }
  }
  .shapes {
    position: relative;
    .shape {
      box-shadow: 0 20px 50px #185b7d3a;
      -webkit-animation: vibrate 8s linear infinite both;
      animation: vibrate 8s linear infinite both;
    }
    .shape-1 {
      position: absolute;
      z-index: 100;
      top: -250px;
      left: 50px;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      animation-delay: 1s;
      background: $pob-primary-color
        linear-gradient(55deg, #2fb5f9 0%, #00e7ff 100%) 0% 0% no-repeat;
    }
    .shape-2 {
      position: absolute;
      z-index: 100;
      top: -290px;
      left: 150px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      animation-delay: 5s;
      background: $pob-primary-color
        linear-gradient(55deg, #2fb5f9 0%, #00e7ff 100%) 0% 0% no-repeat;
    }
  }
  .content {
    padding-right: 30px;
    h1.title {
      font-size: 60px * $scale;
      font-weight: 700;
    }
    p {
      font-size: 40px * $scale;
      font-weight: 300;
      max-width: 360px;
      margin-bottom: 1.5rem;
    }
    h1,
    p,
    strong {
      color: $pob-light-color;
    }
    @include xs {
      h1 {
        font-size: 25px !important;
        padding-bottom: 10px !important;
        padding-top: 15px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 23px !important;
      }
    }
    @media only screen and (min-width:700px) and (max-width: $tablet ) {
      h1 {
        font-size: 35px !important;
        padding-bottom: 10px !important;
        padding-top: 15px !important;
      }
      p {
        font-size: 16px !important;
        line-height: 23px !important;
      }
    }
  }
  .form-container {
    background-color: $pob-dark-color;
    padding: 50px * $scale 70px * $scale;
    border-radius: $border-radius;
    margin-top: -200px;
    .field {
      padding: 8px * $scale 0;
    }
    label.label,
    .checkbox {
      color: $pob-light-color;
    }
    label {
      font-weight: 500;
    }
    button.button {
      color: $pob-light-color;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: $tablet - 1px) {
    .form-container {
      width: 80%;
      padding: 30px * $scale 30px * $scale;
      margin: 0 -10px;
    }
  }
  @include xs {
    .form-container {
      width: 100%;
      padding: 30px * $scale 30px * $scale;
      margin: 0 -10px;
    }
  }
}
