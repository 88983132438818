@import '../common/variables.scss';

#neden-pob {
  @include  xs {
    h1.title {
      font-size: 25px !important;
      text-align: center;
      padding-bottom: 15px !important;
    }
    .pin-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h2 {
        padding-top: 12px !important;
        font-size: 20px !important;
        line-height: 25px !important; 
        strong: {
          font-weight: 700;
        }
        span {
          font-weight: 300;
        }
      }
  
      p {
        padding: 10px 23px !important;
        font-weight: 300;
        font-size: 14px !important;
      }
    }
    .pin {
      border-radius: 50%;
      width: 75px !important;
      height: 75px !important;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $pob-primary-color
        linear-gradient(
          350deg,
          lighten($pob-primary-color, 5) 0%,
          $pob-secondary-color 100%
        );
      box-shadow: 0px 20px 50px rgba($pob-dark-color, 0.12);
      img {
        height: 55px !important;
        margin-bottom: 20px;
      }
    }
  }


  h1.title {
    font-size: 60px * $scale;
    text-align: center;
    padding-bottom: 45px * $scale;
  }
  .pin-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      padding-top: 32px;
      font-size: 30px;
      line-height: 40px;
      strong: {
        font-weight: 700;
      }
      span {
        font-weight: 300;
      }
    }

    p {
      padding: 16px 30px;
      font-weight: 300;
    }
  }
  .pin {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $pob-primary-color
      linear-gradient(
        350deg,
        lighten($pob-primary-color, 5) 0%,
        $pob-secondary-color 100%
      );
    box-shadow: 0px 20px 50px rgba($pob-dark-color, 0.12);
    img {
      height: 110px;
      margin-bottom: 20px;
    }
  }
}
