@import 'fonts.scss';

$scale: 0.75;
$max-width: 1200px;
$font-family: 'Uni Neue', sans-serif;
$border-radius: 10px;

$pob-dark-color: #2e2a45;
$pob-light-color: #ffffff;
$pob-light-color-alternate: #efeff4;

$pob-primary-color: #0098ce;
$pob-secondary-color: #00ffff;
$pob-tetriary-color: #5ac8fa;

$pob-yellow-color: #ffcc00;
$pob-orange-color: #ff9500;
$pob-red-color: #ff2d55;
$pob-green-color: #4cd964;

$pob-black-color: #000000;
$pob-gray-color: #666666;

$pob-background: $pob-light-color;
$pob-background-alternate: $pob-light-color-alternate;

// Override Bulma Variables
$family-sans-serif: $font-family;
$primary: $pob-primary-color;
$warning: $pob-orange-color;
$danger: $pob-red-color;
$success: $pob-green-color;
$text: $pob-dark-color;
$text-strong: $pob-dark-color;
$text-light: $pob-dark-color;
