@import "../common/variables.scss";

@-webkit-keyframes vibrate {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(5px, -5px);
    transform: translate(5px, -5px);
  }
  40% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  60% {
    -webkit-transform: translate(-5px, 5px);
    transform: translate(-5px, 5px);
  }
  80% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes vibrate {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(5px, -5px);
    transform: translate(5px, -5px);
  }
  40% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  60% {
    -webkit-transform: translate(-5px, 5px);
    transform: translate(-5px, 5px);
  }
  80% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

#top-hero {
  .hero-body {
    height: 657px * $scale;
    background: transparent url("../assets/POB_App_Hero.png") 0% 0% no-repeat
      padding-box;
    background-size: cover;
  }
  .content-container {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    width: 100%;
  }
  .shapes {
    position: relative;
    .shape {
      box-shadow: 0 20px 50px #185b7d3a;
      -webkit-animation: vibrate 8s linear infinite both;
      animation: vibrate 8s linear infinite both;
    }
    .shape-1 {
      position: absolute;
      z-index: 100;
      top: -120px;
      left: 55%;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      animation-delay: 3s;
      background: $pob-primary-color
        linear-gradient(55deg, #2fb5f9 0%, #00e7ff 100%) 0% 0% no-repeat;
    }
    .shape-2 {
      position: absolute;
      z-index: 100;
      top: 125px;
      right: 25px;
      height: 90px;
      width: 90px;
      animation-delay: 5s;
      border-radius: 50%;
      background: $pob-primary-color
        linear-gradient(55deg, #2fb5f9 0%, #00e7ff 100%) 0% 0% no-repeat;
    }
    .shape-3 {
      position: absolute;
      z-index: 100;
      top: 265px;
      left: 25%;
      height: 190px;
      animation-delay: 1s;
      width: 190px;
      border-radius: 50%;
      background: $pob-primary-color
        linear-gradient(235deg, #2fb5f9 0%, #00e7ff 100%) 0% 0% no-repeat;
    }
    .shape-4 {
      position: absolute;
      z-index: 99;
      top: 355px;
      left: 23%;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      background: $pob-dark-color;
    }
  }
  .pob-hero {
    height: 100%;
    margin-top: auto;
    .section-image {
      background-position: right center;
    }
    .button {
      font-size: large;
      @include xs {
        font-size: unset;
      }
    }
    .content {
      color: $pob-light-color;
      max-width: 600px * $scale;
      h1,
      span,
      p,
      strong {
        color: $pob-light-color;
      }
      h1 {
        font-size: 60px * $scale;
        line-height: 80px * $scale;
        font-weight: 300;
        margin-bottom: 12px;
        strong {
          font-weight: 700;
        }
        text-shadow: 2px 2px 10px #093e8429;
      }
      p {
        font-size: 2rem * $scale;
      }
      @include xs {
        h1 {
          font-size: 30px;
          line-height: 80px * $scale;
        }
        p {
          font-size: 1rem ;
        }
      }
    }
    @media only screen and (max-width: $tablet - 1px) {
      .content {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
